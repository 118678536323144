<template>
  <div class="participerpage">
    <NavBar />
    <BannerCompo />
    <div class="participercontainer">
      <h2 class="titrepagepart">Participer : </h2>
      <div class="consignes">
        <h3 class="titresectionpart">Consignes : </h3>
        <p>
          Vous pouvez candidater au prix Odette Massfleder directement depuis ce site <b>Passion Nouvelle</b>. <br />
          ATTENTION, pour ce faire, vous devrez avoir renseigner toute la partie <span style="font-family:'Dancing Script', cursive;font-size:1.5em;">Participer</span> . De plus, votre nouvelle devra être enregistrée au format pdf et ne devra pas excèder 2GO. Une fois ces conditions remplies, cliquez sur le bouton choisir un fichier (ci-dessous), et explorez votre répertoire pour trouver votre nouvelle. Puis double-cliquez dessus. Vous pourrez ensuite appuyer sur le bouton envoyer.
        </p>
        <br />
        <p>
          Attention, même si vous participez via le site vous devez vous acquitter des frais d'inscription (cf <a id="inline" href="/reglement">règlement - article 2</a>).
        </p>
      </div>
      <h3 class="titresectionpart">Participer : </h3>
      <form @submit.prevent="submitForm" style="max-width: 80%;">
        <div class="field">
          <label class="titresoussection">Nom</label>
          <div class="control">
            <input v-model="formData.lastName" class="input" type="text" placeholder="Dupond" required />
          </div>
        </div>
        <div class="field">
          <label class="titresoussection">Prénom</label>
          <div class="control">
            <input v-model="formData.firstName" class="input" type="text" placeholder="Antoine" required />
          </div>
        </div>
        <div class="field">
          <label class="titresoussection">Email</label>
          <div class="control">
            <input v-model="formData.email" class="input" type="email" placeholder="antoinedupond@mail.com" required />
          </div>
        </div>
        <div class="field">
          <input id="file" type="file" accept=".pdf" @change="handleFileUpload" required style="display: none;" />
          <label for="file" class="button">Choisir un fichier</label>
        </div>
        <div class="field">
          <div class="control">
            <label class="checkbox">
              <input type="checkbox" required /> J'ai pris connaissance du <a href="/reglement" class="" id="inline">règlement</a>
            </label>
          </div>
        </div>
        <div class="field">
          <div class="control">
            <label class="checkbox">
              <input type="checkbox" required /> J'ai conscience que je dois m'acquitter des frais de participation, même si je candidate par mail.
            </label>
          </div>
        </div>
        <div class="field">
          <div class="control">
            <label class="checkbox" required="">
              <input type="checkbox" /> J'ai bien ajouté à ma nouvelle une page de garde détaillant mon nom, mon prénom et mon adresse mail.
            </label>
          </div>
        </div>
        <div class="field is-grouped">
          <div class="control">
            <button id="button" type="submit">Valider la candidature</button>
          </div>
        </div>
      </form>
      <div v-if="isLoading" class="loader"></div> <!-- Loader element -->
      <p>
        Télécharger <a id="inline" href="RIB.pdf" download="RIB.pdf"> le rib de l'association </a> pour s'acquitter des frais de règlements
      </p>
    </div>
    <FooterCompo />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterCompo from '@/components/FooterCompo.vue';
import BannerCompo from '@/components/BannerCompo.vue';

export default {
  name: 'ParticiperPage',
  components: {
    NavBar,
    FooterCompo,
    BannerCompo
  },
  data() {
    return {
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        file: null
      },
      isLoading: false
    };
  },
  methods: {
    handleFileUpload(event) {
      this.formData.file = event.target.files[0];
    },
    resetForm() {
      this.formData = {
        firstName: '',
        lastName: '',
        email: '',
        file: null
      };
      document.getElementById('file').value = null;
    },
    async submitForm() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append('firstName', this.formData.firstName);
      formData.append('lastName', this.formData.lastName);
      formData.append('email', this.formData.email);
      formData.append('file', this.formData.file);

      try {
        const response = await fetch('https://tasteful-bramble-slicer.glitch.me/submit-form', {
          method: 'POST',
          body: formData
        });
        const result = await response.text();
        setTimeout(() => {
          this.isLoading = false;
          alert('Votre candidature a bien été envoyée !');
          this.resetForm();
        }, 2000);
      } catch (error) {
        console.error('Error:', error);
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.participerpage {
  background-color: white;
}

.participercontainer {
  padding-left: 2em;
  padding-right: 2em;
}

.titrepagepart {
  margin-top: .5em;
  font-family: Amatic SC,cursive;
  font-size: 3em;
  font-weight: bold;
  margin-bottom: .5em;
}

.consignes {
  margin-bottom: 2em;
}

.titresectionpart {
  font-family: Dancing Script,cursive;
  font-size: 2.5em;
}

#inline {
  color: #319093;
}

.titresoussection {
  font-family: Dancing Script,cursive;
  font-size: 2em;
  color: #319093;
}

#button {
  background-color: #319093;
  color: #fff;
  padding: .5em 1em;
  border-radius: 5px;
  font-size: 1.25em;
  font-family: Dancing Script,cursive;
  text-decoration: none;
}

/* Loader styles */
.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #319093; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>